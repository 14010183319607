import logo from '../src/images/HazelRuseLogo.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className='hr-logo' alt="logo" />
        <h2>The Hazel Ruse Website</h2>
        <p>There's nothing here</p>
      </header>
    </div>
  );
}

export default App;
